import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Treasurer() {
  return (
    <>
    <section className='bg-light p-1'>
<Container>
<Breadcrumb >
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item >About Us</Breadcrumb.Item>
      <Breadcrumb.Item >Our Members</Breadcrumb.Item>
      <Breadcrumb.Item active>TREASURER DESK</Breadcrumb.Item>
    </Breadcrumb>
</Container>
</section>

<Container className='pt-5'>
    <Row>
      <Col md={6} style={{textAlign:'center',justifyContent:'space-between',flexDirection:'column'}}>
      {/* <b>TREASURER DESK</b><br/> */}
      {/* <img width={"50%"} src='https://sindhusagaracademy.co.in/files/images/about/member-placeholder.png' /> */}
      {/* <img width={"50%"} src='https://sindhusagaracademy.co.in/files/banners/badlani.jpg' /> */}
      <img width={"50%"} src='/AhujaSir.jpeg' />
    <h1>MR. DEEPAK RAMESH AHUJA</h1>
    <b>TREASURER DESK</b><br/>
      </Col>
      <Col md={6}>
        <ul style={{textAlign:'justify', listStyleType:'none'}}>
          {/* <li style={{paddingBottom:5}}>At Sindhu Sagar Academy English Medium High School And R.K.Kalani JR. College we help students gain knowledge, wisdom, and intellectual, physical, and spiritual growth. We encourage students to be creative problem solvers. Only then can there be generations of peacemakers who realize the purpose of this priceless gift called life.</li> */}
          <li style={{paddingBottom:5}}>As the Treasurer of the Nashik Sindhi Shikshan Mandal, I am honored to have the
        opportunity to serve our esteemed institution. Our Mandal is committed to providing
          a nurturing environment for the holistic development of our students.</li>
          {/* <li style={{paddingBottom:5}}>Sindhu Sagar Academy English Medium High School And R.K.Kalani JR. College offers an excellent curriculum to help students flourish and develop their talents. Each day at Sindhu Sagar Academy English Medium High School And R.K.Kalani JR. College  blooms into a scintillating trail to be treaded upon both by the educators and the students. Students are given opportunities to grow and achieve greatness through a wide variety of learning activities.</li> */}
          <li style={{paddingBottom:5}}>I believe that a strong financial foundation is essential to achieving our goals.
          Therefore, I strive to ensure that our resources are utilized effectively and efficiently.
          By working closely with the management team, we have implemented strategies to
          enhance our financial stability and support the growth of our educational programs.</li>
          <li style={{paddingBottom:5}}>I encourage all members of our community to contribute to the Mandal&#39;s success.
          Your support, whether through donations or active participation, is invaluable in
          helping us create a brighter future for generations to come.</li>
         
        </ul>
      </Col>
    </Row>
  </Container>
    </>
  
  )
}

export default Treasurer