import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Jointsecretary() {
  return (
<>
<section className='bg-light p-1'>
<Container>
<Breadcrumb >
      <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item >About Us</Breadcrumb.Item>
      <Breadcrumb.Item >Our Members</Breadcrumb.Item>
      <Breadcrumb.Item active>Joint Secretary Desk</Breadcrumb.Item>
    </Breadcrumb>
</Container>
</section>



    <Container className='pt-5'>
      
    <Row>
      <Col md={6} style={{textAlign:'center',justifyContent:'space-between',flexDirection:'column'}}>
      {/* <b>JOINT SECRETARY DESK</b><br/> */}
      {/* <img width={"50%"} src='https://sindhusagaracademy.co.in/files/images/about/member-placeholder.png' /> */}
      {/* <img width={"50%"} src='https://sindhusagaracademy.co.in/files/banners/BATRA%20SIR.jpg' /> */}
      <img width={"50%"} src='/HanswaniSir.jpeg' />
    <h2>MR. DHARAMPAL JODHARAM HANSWANI</h2>
    <b>JOINT SECRETARY DESK</b><br/>
      </Col>
      <Col md={6}>
        <ul style={{textAlign:'justify', listStyleType:'none'}}>
          {/* <li style={{paddingBottom:5}}>Education is a means by which we train children to deal with the problems of life. To bring out the right balance in life, we also have to lay stress on the spiritual aspect of education. Spirituality deals with developing the higher values of life and becoming a better person.</li> */}
          <li style={{paddingBottom:5}}>As we are celebrating the 75th anniversary of HAB Sindhi School (now Sindhu Sagar Academy), I am
          filled with an overwhelming sense of pride and nostalgia. This milestone marks not just a passage of
          time, but the collective efforts of visionaries, educators, and students who have contributed to the
          institution&#39;s legacy of excellence.</li>
          {/* <li style={{paddingBottom:5}}>To develop our spiritual side it is important to start the basic moral learning at an early age. It is important that spiritual and ethical values of life are taught properly. We can develop spiritually through a process of meditation. Through meditation we not only develop the higher human values, but we also realize our true-selves and gain knowledge about the soul in our body. If we teach the ethical & spiritual traits along with the physical and intellectual traits to children at the school level, then this world will become a heaven of joy, peace and harmony.</li>
          <li style={{paddingBottom:5}}>Ethical and Spiritual development means learning about spreading love, kindness, being truthful, being helpful, caring and humble human being. The students also learn non-violence, humility, compassion & selfless service.</li> */}
          <li style={{paddingBottom:5}}>As someone who has had the privilege of walking these corridors as a student and now serving as a
          member of the managing committee, I feel deeply connected to this incredible journey. Sindhi
          School emerged as a landmark of learning for the Sindhi community, offering quality education while
          preserving our cultural heritage. Over the years, it has blossomed into a respected institution that
          fosters academic rigor, creativity, and holistic development.</li>
        
        </ul>
      </Col>
    </Row>
  </Container>
  </>
  )
}

export default Jointsecretary